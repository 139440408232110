import '../styles';
import { useEffect, useState } from 'react';
import { Panel } from 'react-resizable-panels';
import { TextField,InputLabel,Select,MenuItem,Button } from '@mui/material';
import { API, Utils } from '../util';
import toast from 'react-hot-toast';
import { EventForm, EmailSelectPopup, NoneForm, TextForm, NewsForm, NewsLongForm } from '.';
import { useAppContext, defaultState } from '../context';
import { Template } from '../templates';

export function MiddlePanel() {
  const { state, setState } = useAppContext();
  const [recipients, setRecipients] = useState<string[]>([]);
  const [testRecipients, setTestRecipients] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>('None');
  const [showTestPopup, setShowTestPopup] = useState(false);

  const [removedEmailsText, setRemovedEmailsText] = useState<string>('');


  // Get all the student accounts
  useEffect(() => {
    async function getAccounts() {
      try {
        let result = await API.GET_ACCOUNTS();
        Utils.setAccounts(result.accounts);
        setRecipients(Utils.getEmails('None'));
      } 
      catch (error) {
        console.log(error);
      }
    }
    getAccounts();
  }, []);

  async function sendEmail(method: 'test'|'default') {
    // Check if all fields have been filled out
    if (state.form.subject === '' || state.form.template?.fieldsNotFilled(state.form.fields)) return; 
    // Hide the test email popup
    setShowTestPopup(false);
    // Get who to send the email to
    let sendTo = method === 'default' ? recipients : testRecipients;

    // filter out the emails that should be removed
    let emailsToRemove = removedEmailsText.split(',').map(e => e.trim()).filter(e => e !== '');
    sendTo = sendTo.filter(e => !emailsToRemove.includes(e));

    // Send the email
    if (sendTo.length > 0) {
      // Ensure the user wants to send the email
      let sure = false;
      if (method === 'default') {
        const wantToSend = window.confirm(`This email will be sent to ${sendTo.length} student${sendTo.length === 1 ? '' : 's'}.`);
        if (wantToSend) sure = window.confirm("Are you sure");
      }
      else {
        sure = true;
      }
      // Send the email
      if (sure) {
        try {
          // Update the HTML with the tag
          let tag = state.form.tag !== '' ? `${state.form.tag}` : undefined;
          let html = state.form.template ? state.form.template.ADD_TAG_FROM_HTML(state.form.contentHTML, tag) : state.form.contentHTML;
          // Send the email
          await API.SEND_EMAIL(state.form.subject, Template.HTML_TO_TEXT(state.form.contentHTML), html, sendTo, 'Sent', tag);
          setState({...defaultState, refreshEmailList: true});
          toast.success("Sent!");
        } 
        catch (error) {
          console.log(error);
          toast.error("Server Error");
          setState(defaultState);
        }
      }
    }
    else {
      window.alert('Cannot send email since there are no recipients.');
    }
  }

  function renderFormInput() {
    if (!state.selectedEmail) return;
    let sent = !state.viewingDraft;
    let template = sent ? Template.GET_TEMPLATE_FROM_HTML(state.selectedEmail.contentHTML) : state.form.template;
    return (
      <div>
        {/* {<NoneForm disabled={sent} value={sent ? state.selectedEmail.contentHTML : state.form.fields.html}/> } */}
        {!template && <NoneForm disabled={sent} value={sent ? state.selectedEmail.contentHTML : state.form.fields.html}/> }
        {template?.name === 'Text' && <TextForm disabled={sent}/> }
        {template?.name === 'Event' && <EventForm disabled={sent}/> }
        {template?.name === 'News' && <NewsForm disabled={sent}/> }
        {template?.name === 'News Long' && <NewsLongForm disabled={sent}/> }
      </div>
    );

  }

  function renderForm() {

    function renderEmailInfo() {
      let e = state.selectedEmail;

      function getRecepientsText() {
        if (!e) return '';
        else if (e.emails.length === 1) return e.emails[0];
        else if (e.emails.length === 2) return e.emails[0] + ' and 1 other.';
        else return e.emails[0] + ` and ${e.emails.length - 1} others.`;
      }

      function getRecipientCount() {
        let emailsToRemove = removedEmailsText.split(',').map(e => e.trim());
        let count = recipients.filter(r => !emailsToRemove.includes(r)).length;
        return count;
      }
  
      if (e && !state.viewingDraft) {
        let created = new Date(e.createdAt);
        let day = Utils.getRelativeDay(created);
        let time = Utils.getTimeOfDay(created);
        return(
          <div className='recepientCountContainer'>
            {`This email was sent to ${e.emails.length} student${e.emails.length === 1 ? '' : 's'} ${day === 'Today' || day === 'Yesterday' ? '' : 'on'} ${day} at ${time}.`}
            <br/>
            {`Recepients - ${getRecepientsText()}`}
            {e.openCount !== undefined && <><br/> Opens - {e.openCount} out of {e.emails.length}.</>}       
          </div>
        );
      }
      return(
        <div className='recepientCountContainer'>
          {`This email will be sent to ${getRecipientCount()} students.`}
        </div>
      );
    }

    function renderSubject() {
      if (!state.selectedEmail) return;

      function onChange(val: string) {
        if (val.length <= 40) setState({...state, form: {...state.form, subject: val}});
      }

      return (
        <TextField label="Subject" fullWidth margin="normal" required 
          disabled = {!state.viewingDraft}
          value={state.viewingDraft ? state.form.subject : state.sentForm.subject } 
          onChange={(e) => onChange(e.target.value)} 
        />
      );
    }

    function renderTemplateSelect() {
      if (!state.selectedEmail) return;

      function onTemplateChange(templateName: string) {
        let template = Template.GET_TEMPLATE_FROM_NAME(templateName);
        setState({...state, form: {...state.form, contentHTML: template?.getHTML() ?? '', template, fields: {}}});
      }

      let value = (state.viewingDraft ? state.form.template?.name : state.sentForm.template?.name) ?? 'None';
      return (
        <div style={{ flex: '33%', marginRight: '10px' }}>
          <InputLabel>Template</InputLabel> 
          <Select label="Type" variant="outlined" fullWidth required value={value} 
            disabled = {!state.viewingDraft}  
            onChange={(e) => onTemplateChange(e.target.value) }>
              <MenuItem value="Text">Text</MenuItem>
              <MenuItem value="Event">Event</MenuItem>
              <MenuItem value="News">News</MenuItem>
              <MenuItem value="News Long">News Long</MenuItem>
              <MenuItem value="None">None</MenuItem>
          </Select>
        </div>
      );
    }

    function renderFilterSelect() {
      if (!state.viewingDraft) return;

      function onFilterChange(filter: string) {
        setFilter(filter);
        setRecipients(Utils.getEmails(filter));
      }

      return (
        <div style={{ flex: '33%', marginRight: '10px' }}>
          <InputLabel>Filter</InputLabel>
          <Select label="Filter" variant="outlined" fullWidth value={filter} onChange={(e) => onFilterChange(e.target.value)}>
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Chinese">Chinese</MenuItem>
            <MenuItem value="English">English</MenuItem>
          </Select>
        </div>
      );
    }

    function renderTag() {
      if (!state.selectedEmail) return;
      if (state.viewingDraft && state.form.template === null) return;
      if (!state.viewingDraft && state.sentForm.template === null) return;

      function onChange(val: string) {
        if (val.length <= 35) setState({...state, form: {...state.form, tag: val}});
      }

      return (
        <div style={{ flex: '33%', marginRight: '10px' }}>
          <InputLabel>Tag</InputLabel>
          <TextField fullWidth 
            disabled = {!state.viewingDraft}
            value={state.viewingDraft ? state.form.tag : state.sentForm.tag } 
            onChange={(e) => onChange(e.target.value)} 
          />
        </div>
      );
    }

    function renderSubmit() {

      function onDeletePress() {
        const isConfirmed = window.confirm('Are you sure you want to delete?');
        if (isConfirmed) setState(defaultState);
      }

      function onSendSimilarPress() {
        let e = state.selectedEmail;
        if (e) setState({...state, sendingSimilar: true, composing: true}); 
      }

      function onSendTestClick() {
        if (state.form.subject === '' || state.form.template?.fieldsNotFilled(state.form.fields)) return; 
        setShowTestPopup(true);
      }

      if (!state.viewingDraft) {
        return (
          <div className='submitContainer'>
            <Button type="submit" variant="contained" color="primary" onClick={() => onSendSimilarPress()}>Send Similar</Button>
          </div>
        );
      }
      return (
        <div className='submitContainer'>
          <Button variant="contained" color="error" onClick={onDeletePress}>Delete</Button>
          <Button type="submit" variant="contained" color="warning" onClick={() => onSendTestClick()}>Send Test</Button>
          <Button type="submit" variant="contained" color="success" onClick={() => sendEmail('default')}>Send</Button>
        </div>
      );
    }

    return(
      <form onSubmit={(e) => e.preventDefault()} className='formContainer'>
        {renderEmailInfo()}
        {renderSubject()}
        <div style={{ display: 'flex' }}>
          { renderTemplateSelect() }
          { renderFilterSelect() }
          { renderTag() }
        </div>
        {renderFormInput()}
        {renderSubmit()}
      </form>
    );
  }

  function renderTestEmailPopup() {

    let options: {name: string, email: string}[] = [
      {name: 'Cameron Ross', email: 'cameron.edward.ross@gmail.com'},
      {name: 'Siqi Zhang', email: 'siqizhang1107@gmail.com'},
      {name: 'Dr. Yu Sun', email: 'yu.sun.cs@gmail.com'},
      {name: 'Tao Li', email: 'tao.li.purdue@gmail.com'},
    ];

    return <EmailSelectPopup 
            onChange={(names) => setTestRecipients( options.filter(o => names.includes(o.name)).map(o  => o.email) )} 
            onClose={() => setShowTestPopup(false)} 
            onSubmit={() => sendEmail('test')} 
            options={options}
          />;
  }

  function renderRemoveEmailField() {
    if (!state.selectedEmail) return;

    function renderText() {
      if (removedEmailsText === '') return;
      let emails = removedEmailsText.split(',').map(e => e.trim()).filter(e => e !== '');
      return `Will remove ${emails.length} email${emails.length === 1 ? '' : 's'}.`;
    }

    return (
      <>
        <TextField label="Remove Emails" fullWidth margin="normal" required 
          disabled = {!state.viewingDraft}
          value={removedEmailsText} 
          onChange={(e) => setRemovedEmailsText(e.target.value)} 
        />
        <div className='recepientCountContainer'>{renderText()}</div>
      </>
    );
  }

  

  return (
    <Panel minSize={20} defaultSize={40} className='panelContentContainer'>
      { !state.selectedEmail && <div className='selectEmailMessage'>Select an email or compose a new one.</div> }
      { state.selectedEmail && renderForm()}
      { showTestPopup && renderTestEmailPopup()}
      
      {renderRemoveEmailField()}
    </Panel>
  );

}

