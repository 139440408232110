import { NewsLongFields } from "../types";
import { Template } from "./Template";

export class NewsLongTemplate extends Template {

  constructor() {
    super('News Long');
  }

  protected insertFields(fields: NewsLongFields): string {
    const doc = new DOMParser().parseFromString(this.template, 'text/html');
    // Replace the title content
    const titleElement = doc.querySelector('[field="title"]');
    if (titleElement) titleElement.textContent = fields.title;
    // Replace the body content
    const bodyElement = doc.querySelector('[field="body"]');
    if (bodyElement && fields.body) bodyElement.innerHTML = fields.body.replace(/\n/g, '<br>');
    // Replace the body2 content
    const body2Element = doc.querySelector('[field="body2"]');
    if (body2Element && fields.body2) body2Element.innerHTML = fields.body2.replace(/\n/g, '<br>');
    // Replace the img content
    const imageElement = doc.querySelector('img[field="image"]');
    if (imageElement) imageElement.setAttribute('src', fields.image);
    // Replace the img content
    const image2Element = doc.querySelector('img[field="image2"]');
    if (image2Element) image2Element.setAttribute('src', fields.image2);
    // Replace the button text
    const buttonElement = doc.querySelector('a[field="button"]');
    if (buttonElement) buttonElement.textContent = fields.buttonText;
    // Replace all links
    const imageLink = doc.querySelector('a[field="image"]');
    const image2Link = doc.querySelector('a[field="image2"]');
    const buttonLink = doc.querySelector('a[field="button"]');
    if (imageLink) imageLink.setAttribute('href', fields.link);
    if (image2Link) image2Link.setAttribute('href', fields.link);
    if (buttonLink) buttonLink.setAttribute('href', fields.link);
    // return the result
    return doc.documentElement.outerHTML;
  }

  public getFields(htmlString: string): NewsLongFields {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    let result: NewsLongFields = {
      title: doc.querySelector('[field="title"]')?.textContent ?? '',
      body: doc.querySelector('[field="body"]')?.textContent ?? '',
      body2: doc.querySelector('[field="body2"]')?.textContent ?? '',
      link: doc.querySelector('a[field="when"]')?.getAttribute('href') ?? '',
      buttonText: doc.querySelector('a[field="button"]')?.textContent ?? '',
      image: doc.querySelector('img[field="image"]')?.getAttribute('src') ?? '',
      image2: doc.querySelector('img[field="image2"]')?.getAttribute('src') ?? '',
    };
    return result;
  }

  public fieldsNotFilled(fields: any): boolean {
    if (!fields.title) return true;
    if (!fields.link) return true;
    if (!fields.buttonText) return true;
    if (!fields.image) return true;
    if (!fields.image2) return true;
    if (!fields.body) return true;
    if (!fields.body2) return true;
    return false;
  }
  
  
}