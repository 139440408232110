import './styles';
import { useEffect } from 'react';
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Template  } from './util';
import { AppProvider } from './context';
import { LoginScreen, HomeScreen } from './pages';
import { Template } from './templates';

function App() {
  // Load all the templates
  useEffect(() => {
    Template.LOAD_ALL();
  }, []);


  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeScreen/>} />
          <Route path="/login" element={<LoginScreen/>} />
        </Routes>  
      </BrowserRouter>
      <Toaster/>
    </AppProvider>
  );

}


export default App;
