import { TextTemplate, EventTemplate, NewsTemplate, NewsLongTemplate } from ".";
import { BaseTemplatesContent } from "./BaseTemplatesContent";

export abstract class Template {
  // Instance variables of each template
  name!: string;
  template!: string;
  // A reference to all templates. Initialized in LOAD_ALL().
  static LIST: Template[] = [];
  
  constructor(name: string) {
    this.Initialize(name);
  }

  /** Load all the templates. */
  public static LOAD_ALL() {
    this.LIST.push(new TextTemplate());
    this.LIST.push(new EventTemplate());
    this.LIST.push(new NewsTemplate());
    this.LIST.push(new NewsLongTemplate());
  }

  /** Get the template used from an HTML string. */
  public static GET_TEMPLATE_FROM_HTML(htmlString: string): Template | null{
    // Get the <html> element
    const htmlElement = new DOMParser().parseFromString(htmlString, 'text/html').querySelector('html');
    // Get the value of the 'template' attribute, if it exists
    let name = htmlElement?.getAttribute('template') ?? 'None';
    // Return the template
    return this.GET_TEMPLATE_FROM_NAME(name);
  }

  /** Get the tag used from an HTML string. */
  public static GET_TAG_FROM_HTML(htmlString: string): string {
    // Get the <html> element
    const htmlElement = new DOMParser().parseFromString(htmlString, 'text/html').querySelector('html');
    // Get the value of the 'tag' attribute, if it exists
    let tag = htmlElement?.getAttribute('tag') ?? '';
    // Return the tag
    return tag;
  }

  /** Add the tag to HTML */
  public ADD_TAG_FROM_HTML(htmlString: string, tag: string | undefined): string {
    if (!tag) return htmlString;
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    const htmlElement = doc.querySelector('html');
    if (htmlElement) htmlElement.setAttribute('tag', tag);
    return doc.documentElement.outerHTML;
  }


  /** Get the template used from the name. */
  public static GET_TEMPLATE_FROM_NAME(name: string): Template | null {
    for (let index = 0; index < this.LIST.length; index++) {
      const element = this.LIST[index];
      if (element.name === name) return element;
    }
    return null;
  }

  /** Convert html to plain text. */
  public static HTML_TO_TEXT(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  /** Get the HTML of a template. */
  public getHTML(fields?: any): string {
    // Return the unmodified template content if no fields are given
    if (!fields) return this.template;
    // return the result
    return this.insertFields(fields);
  }

  /** Load a template. */
  private async Initialize(name: string) {
    this.name = name;
    if (name === 'Text') this.template = BaseTemplatesContent.Text;
    else if (name === 'Event') this.template = BaseTemplatesContent.Event;
    else if (name === 'News') this.template = BaseTemplatesContent.News;
    else if (name === 'News Long') this.template = BaseTemplatesContent.NewsLong;
  }

  abstract getFields(htmlString: string): any;
  abstract fieldsNotFilled(fields: any): boolean;
  protected abstract insertFields(fields: any): string;

}
