import '../styles';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { API, Utils } from '../util';
import { useAppContext } from '../context';


export function LoginScreen() {
  const { state, setState } = useAppContext();
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function onLoginPress() {
    let success = await API.LOGIN(username, password);
    setState({...state, authenticated: success});
    if (!success) toast.error("Login Failed");
    else navigate('/');
  }

  return(
    <div className="page">
      <Dialog open={true}>
        <img className='loginLogo' src='https://codingmind.com/assets/img/codingmindlogo.png'/>
        <h2 style={{textAlign: 'center'}}>{Utils.getGreeting()} - {API.ENV === 'dev' ? "Development" : ""}</h2>
        <DialogTitle>Login (SMW)</DialogTitle>
        <DialogContent>
          <TextField label="Username" margin="normal" fullWidth name="username" value={username} onChange={(e) => setUsername(e.target.value)}/>
          <TextField label="Password" type="password" margin="normal" fullWidth name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onLoginPress} color="primary" variant="contained">Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}
