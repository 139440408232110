import { TextFields } from "../types";
import { Template } from "./Template";

export class TextTemplate extends Template {

  constructor() {
    super('Text');
  }

  protected insertFields(fields: TextFields): string {
    const doc = new DOMParser().parseFromString(this.template, 'text/html');
    // Replace the title content
    const titleElement = doc.querySelector('[field="title"]');
    if (titleElement) titleElement.textContent = fields.title;
    // Replace the text content
    const textElement = doc.querySelector('[field="text"]');
    if (textElement && fields.text) textElement.innerHTML = fields.text.replace(/\n/g, '<br>');
    // return the result
    return doc.documentElement.outerHTML;
  }

  public getFields(htmlString: string): TextFields {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    let result: TextFields = {
      title: doc.querySelector('[field="title"]')?.textContent ?? '',
      text: doc.querySelector('[field="text"]')?.textContent ?? ''
    };
    return result;
  }
  
  public fieldsNotFilled(fields: TextFields): boolean {
    if (!fields.title) return true;
    if (!fields.text) return true;
    return false;
  }
  
}