import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, FormControl, Select, OutlinedInput, Chip, ListItemAvatar, Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { EmailSelectPopupProps } from '../types';



export function EmailSelectPopup(props: EmailSelectPopupProps) {
  const { options, onClose, onChange, onSubmit } = props;
  const [selected, setSelected] = useState<string[]>([]);

  function onOptionsChange(value: any) {
    setSelected(value);
    onChange(value);
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown open={true} onClose={onClose} fullWidth>
        {/* Render Title */}
        <DialogTitle>Choose Test Emails</DialogTitle>
        {/* Render content */}
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Select</InputLabel>
            <Select multiple value={selected}
              onChange={(e) => onOptionsChange(e.target.value)}
              input={<OutlinedInput label="Chip" />}
              renderValue={(selected) => (<div>{selected.map((val) => (<Chip avatar={<Avatar>{val[0]}</Avatar>} key={val} label={val}/>))}</div>)}
            >
              {options.map((o) => (<MenuItem key={o.email} value={o.name}>
                <ListItemAvatar><Avatar>{o.name[0]}</Avatar></ListItemAvatar>
                {o.name}
              </MenuItem>))}
            </Select>
          </FormControl>
        </DialogContent>
        {/* Render Buttons */}
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSubmit}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
