import { IconButton, TextField } from '@mui/material';
import { NewsFields, TemplateFieldProps } from '../../types';
import { useAppContext } from '../../context';

export function NewsForm(props: TemplateFieldProps) {
  const { disabled } = props;
  const { state, setState } = useAppContext();

  let fields = state.viewingDraft ? state.form.fields : state.sentForm.fields as NewsFields;

  function onFieldChange(field: string, val: string) {
    // Change the form fields
    if (field === 'title') fields.title = val;
    if (field === 'image') fields.image = val;
    if (field === 'body') fields.body = val;
    if (field === 'link') fields.link = val;
    if (field === 'buttonText') fields.buttonText = val;
    // Update the HTML
    let html = state.form.template!.getHTML(fields);
    // Update the state
    setState({...state, form: {...state.form, fields, contentHTML: html}});
  }

  return (
    <div>
      {/* Header */}
      <div className='inputFieldHeader'>
        <div className='inputFieldTitle'>Content</div>
      </div>
      {/* Title */}
      <TextField label="Title" fullWidth margin="normal" required
        value={fields.title}
        onChange={(e) => onFieldChange('title', e.target.value) }
        disabled={disabled}
      />
      {/* Image */}
      <TextField label="Image URL" fullWidth margin="normal" required
        value={fields.image}
        onChange={(e) => onFieldChange('image', e.target.value) }
        disabled={disabled}
      />
      {/* Body */}
      <div style={{ margin: '25px 0px', color: "#616161", fontSize: "12pt"}}>
        <TextField label="Body" multiline fullWidth minRows={5} maxRows={10} placeholder={"Enter Text"} variant="filled"
          value={fields.body}
          onChange={(e) => onFieldChange('body', e.target.value) }
          disabled={disabled}
          required
          style={{ marginBottom: "10px" }}
        />
        How to style text:
        <ul>
          <li>{"To bold: "} <span style={{color:"red"}}>{"<strong>Hello</strong>"}</span> {"\u2192 "} <strong>Hello</strong></li>
          <li>{"To underline: "} <span style={{color:"red"}}>{"<u>Hello</u>"}</span> {"\u2192 "} <u>Hello</u></li>
          <li>{"To highlight: "} <span style={{color:"red"}}>{"<mark>Hello</mark>"}</span> {"\u2192 "} <mark>Hello</mark></li>
        </ul>
        You can combine these tags to create more complex formatting.
        <ul>
          <li>{"To bold and underline: "} <span style={{color:"red"}}>{"<strong><u>Hello</u></strong>"}</span> {"\u2192 "} <strong><u>Hello</u></strong></li>
          <li>{"To highlite and bold: "} <span style={{color:"red"}}>{"<strong><mark>Hello</mark></strong>"}</span> {"\u2192 "} <strong><mark>Hello</mark></strong></li>
          <li>{"...and so on"} </li>
        </ul>
      </div>
      
      {/* Button Text */}
      <TextField label="Button Text" fullWidth margin="normal" required
        value={fields.buttonText}
        onChange={(e) => onFieldChange('buttonText', e.target.value) }
        disabled={disabled}
      />
      {/* Link */}
      <TextField label="Link" fullWidth margin="normal" required
        value={fields.link}
        onChange={(e) => onFieldChange('link', e.target.value) }
        disabled={disabled}
      />
    </div>
    
  );
}
