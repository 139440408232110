import { Email, StudentAccount } from "../types";

export class API {

  public static ENV:'prod'|'dev' = process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'dev';
  // private static BASE_URL = `http://localhost:3002/api/`;
  private static BASE_URL = `https://${this.ENV === 'prod' ? 'prod' : 'dev'}-sharemyworks-backend.herokuapp.com/api/`;
  

  /** Get emails. */
  static async GET_EMAILS(limit: number, skip: number) {

    const filter = {
      limit,
      skip,
      order: 'createdAt DESC'
    };

    return this.contactAPI<Email[]>({
      method: "GET",
      url: `EmailNotifications?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    });
  }

  /** Get number of emails. */
  static async GET_EMAIL_COUNT() {
    return this.contactAPI<{count: number}>({
      method: "GET",
      url: `EmailNotifications/count`,
    });
  }

  /** Get email. */
  static async GET_EMAIL(id: string) {
    return this.contactAPI<Email>({
      method: "GET",
      url: `EmailNotifications/${id}`,
    });
  }

   /** Get all accounts. */
  static async GET_ACCOUNTS() {

    const filter = {
      where: {
        roleName: 'student',
        // ...(language !== 'All' ? {preferedLanguage: language} : {})
      },
      fields: {
        id: true,
        email: true,
        email2: true,
        preferedLanguage: true,
      },
    };

    return this.contactAPI<{count: number, accounts: StudentAccount[]}>({
      method: "GET",
      url: `Account?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    });
  }

  /** Send email. */
  static async SEND_EMAIL(subject: string, contentText: string, contentHTML: string, recipients: string[], status: "Draft"|"Sent", tag: string | undefined) {
    console.log(tag);
    return this.contactAPI<{}>({
      method: "POST",
      url: `EmailNotifications`,
      body: { subject, contentText, contentHTML, emails: recipients, status, tag }
    });
  }

  static async GET_EMAIL_READS(tag: string) {
    let count = 0;
    try {
      const response = await fetch('https://api.mailgun.net/v3/stats/filter?event=opened&group=tag', {
        method: 'GET',
        headers: {
          'Authorization': 'Basic ' + btoa(`postmaster@mail.codingmindsacademy.com:${process.env.REACT_APP_MAILGUN_KEY}`),
        }
      });
      if (response.ok) {
        const data = await response.json();
        let stats: {group_key: string, opened: {total: number}}[] = data.stats;
        for (let i = 0; i < stats.length; i++) {
          if (stats[i].group_key === tag) {
            count = stats[i].opened.total;
            break;
          }
        }
      }
    } 
    catch (error) {}
    return count;
  }

  /** Login. */
  static async LOGIN(username: string, password: string) {
    try {
      if (username.length > 1 && password.length > 1) {
        let response = await this.contactAPI<{id?: string, userId?: string, error?: any}>({
          method: "POST",
          url: `Account/login`,
          appendToken: false,
          body: { username, password }
        });
        if (response.error === undefined) {
          this.setCredentials(response.id, response.userId);
          return true;
        }
      }
    } 
    catch (error) {
      console.log(error);
    }
    this.removeCredentials();
    return false;
  }

  /** Logout. */
  static async LOGOUT() {
    try {
      await this.contactAPI<{}>({
        method: "POST",
        url: `Account/logout`,
      });
    } 
    catch (error) {}
    this.removeCredentials();
    return false;
  }

  /** Authenticated?. */
  static async AUTHENTICATED() {
    let token = window.localStorage.getItem("access_token");
    let userID = window.localStorage.getItem("userId");
    try {
      if (!token || !userID) return false;
      else {
        let response = await this.contactAPI<{id: string, userId: string, error?: any}>({
          method: "GET",
          url: `Account/getRole?accountId=${userID}`,
        });
        return response.error === undefined;
      }
    } 
    catch (error) {
      return false;
    }

  }

  /** Remove credentials stored in the browser. */
  private static async removeCredentials() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userId");
  } 

  /** Set credentials stored in the browser. */
  private static async setCredentials(token: string|undefined, userId: string|undefined) {
    if (token) localStorage.setItem("access_token", token);
    if (userId) localStorage.setItem("userId", userId);
  } 

  /** Contact the API. */
  private static async contactAPI<T>(props: ContactAPIProps): Promise<T> {
    try {
      // Extract the request options from the props
      const { method = "GET", body = undefined, url, appendToken: appendToken = true } = props;
      // Format the full url
      let fullURL= this.BASE_URL + url;
      if (appendToken) {
        let symbol = fullURL.includes('?') ? '&' : '?';
        fullURL += `${symbol}access_token=${window.localStorage.getItem("access_token")}`;

      }
      // Format request options for the request 
      let requestOptions: RequestInit = { 
        method: method, 
        headers: { 
          "Content-Type": "application/json", 
        },
        body: (body ? JSON.stringify(body) : undefined),
        mode: 'cors'
      };
      // Contact the API
      let response: Response = await fetch(fullURL, requestOptions);
      // Return the response
      let json: T = await response.json();
      return json;
    } 
    catch (error: any) { 
      throw error;
    }
  }
  
}



type ContactAPIProps = {
  /** The url. */
  url: string,
  /** Should the token be appended to the URL? */
  appendToken?: boolean,
  /** What request method to use. Default is "GET" */
  method: "POST" | "DELETE" | "PATCH" | "GET" | "PUT",
  /** The request body.  */
  body?: any,
}
