import { EventFields } from "../types";
import { Template } from "./Template";

export class EventTemplate extends Template {

  constructor() {
    super('Event');
  }

  protected insertFields(fields: EventFields): string {
    const doc = new DOMParser().parseFromString(this.template, 'text/html');
    // Replace the title content
    const titleElement = doc.querySelector('[field="title"]');
    if (titleElement) titleElement.textContent = fields.title;
    // Replace the when content
    const whenElement = doc.querySelector('[field="when"]');
    if (whenElement) whenElement.textContent = fields.when;
    // Replace the where content
    const whereElement = doc.querySelector('[field="where"]');
    if (whereElement) whereElement.textContent = fields.where;
    // Replace the body content
    const bodyElement = doc.querySelector('[field="body"]');
    if (bodyElement && fields.body) bodyElement.innerHTML = fields.body.replace(/\n/g, '<br>');
    // Replace the img content
    const imageElement = doc.querySelector('img[field="image"]');
    if (imageElement) imageElement.setAttribute('src', fields.image);
    // Replace the button text
    const buttonElement = doc.querySelector('a[field="button"]');
    if (buttonElement) buttonElement.textContent = fields.buttonText;
    // Replace all links
    const imageLink = doc.querySelector('a[field="image"]');
    const whenLink = doc.querySelector('a[field="when"]');
    const whereLink = doc.querySelector('a[field="where"]');
    const buttonLink = doc.querySelector('a[field="button"]');
    if (imageLink) imageLink.setAttribute('href', fields.link);
    if (whenLink) whenLink.setAttribute('href', fields.link);
    if (whereLink) whereLink.setAttribute('href', fields.link);
    if (buttonLink) buttonLink.setAttribute('href', fields.link);
    // return the result
    return doc.documentElement.outerHTML;
  }

  public getFields(htmlString: string): EventFields {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    let result: EventFields = {
      title: doc.querySelector('[field="title"]')?.textContent ?? '',
      when: doc.querySelector('[field="when"]')?.textContent ?? '',
      where: doc.querySelector('[field="where"]')?.textContent ?? '',
      body: doc.querySelector('[field="body"]')?.textContent ?? '',
      link: doc.querySelector('a[field="when"]')?.getAttribute('href') ?? '',
      buttonText: doc.querySelector('a[field="button"]')?.textContent ?? '',
      image: doc.querySelector('img[field="image"]')?.getAttribute('src') ?? '',
    };
    return result;
  }

  public fieldsNotFilled(fields: any): boolean {
    if (!fields.title) return true;
    if (!fields.link) return true;
    if (!fields.buttonText) return true;
    if (!fields.image) return true;
    return false;
  }
  
  
}