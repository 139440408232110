import "../styles";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { LeftPanel, RightPanel, MiddlePanel } from "../components";
import { Logout } from '@mui/icons-material';
import { API } from "../util";
import { useAppContext } from "../context";


export function HomeScreen() {
  const { state, setState } = useAppContext();
  let navigate = useNavigate();

  useEffect(() => {
    async function CheckAuth() {
      let auth = await API.AUTHENTICATED();
      setState({...state, authenticated: auth});
      if (!auth) navigate('/login');
    }
    CheckAuth();
  }, []);
  
  function onLogoutPress() {
    API.LOGOUT();
    setState({...state, authenticated: false});
    navigate('/login');
  }

  return (
    <div className="page">
      {/* Navbar */}
      <div className='navbar'>
        <div className='title'>CM Connect - {API.ENV === 'dev' ? 'Development' : 'Production'}</div>
        <div className='logout' onClick={onLogoutPress}>Logout<Logout style={{margin: 5}}/></div>
      </div>
      {/* Main content */}
      <PanelGroup direction="horizontal" style={{ height: "96vh" }}>
        <LeftPanel/>
        <PanelResizeHandle className="handleContainer"><div className={"handle"}/></PanelResizeHandle>
        <MiddlePanel/>
        <PanelResizeHandle className="handleContainer"><div className={"handle"}/></PanelResizeHandle>
        <RightPanel/>
      </PanelGroup>
    </div>
  );
}
