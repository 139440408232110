import React, { createContext, useState, useContext, PropsWithChildren } from 'react';
import { AppState } from '../types';
import { Template } from '../templates';

// Define the context value type
type AppContextType = {
  state: AppState;
  setState: React.Dispatch<React.SetStateAction<AppState>>;
};

export const defaultState: AppState = {
  form: {
    subject: '',
    contentHTML: '',
    template: null,
    fields: {},
    tag: ''
  },
  sentForm: {
    subject: '',
    contentHTML: '',
    template: null,
    fields: {},
    tag: ''
  },
  composing: false,
  viewingDraft: false,
  refreshEmailList: false,
  sendingSimilar: false,
  selectedEmail: null,
  authenticated: false
};

// Create the context with an appropriate initial value
const AppContext = createContext<AppContextType>({ 
  state: defaultState, 
  setState: () => {} 
});

export const AppProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, setState] = useState<AppState>(defaultState);
  return (
    <AppContext.Provider value={{ state, setState }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) throw new Error("useFormContext must be used inside the FormProvider");
  return context;
};

