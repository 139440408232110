import { IconButton, TextField } from '@mui/material';
import { NoneFields, TemplateFieldProps } from '../../types';
import toast from 'react-hot-toast';
import { FileCopy, Check } from '@mui/icons-material';
import { useState } from 'react';
import { useAppContext } from '../../context';

export function NoneForm(props: TemplateFieldProps & {value?: string}) {
  const { disabled, value } = props;
  const { state, setState } = useAppContext();
  const [copiedRecently, setCopiedRecently] = useState(false);

  let fields = state.viewingDraft ? state.form.fields : state.sentForm.fields as NoneFields;


  function onFieldChange(field: string, val: string) {
    // Update the fields
    if (field === 'html') fields.html = val;
    // Update the state
    setState({...state, form: {...state.form, fields, contentHTML: fields.html}});
  }

  function onCopyPress() {
    if (!copiedRecently) {
      navigator.clipboard.writeText(value ?? fields.html);
      toast.success("Copied!");
      setCopiedRecently(true);
      setTimeout(() => setCopiedRecently(false), 2000);
    }
  }

  return (
    <div>
      {/* Header with copy button */}
      <div className='inputFieldHeader'>
        <div className='inputFieldTitle'>Content</div>
        {!state.viewingDraft && <IconButton size="large" onClick={onCopyPress}>{!copiedRecently ? <FileCopy/> : <Check/>}</IconButton> }
      </div>
      {/* html input */}
      <TextField label={'HTML'} multiline fullWidth required minRows={20} maxRows={30} placeholder={"Enter HTML"} variant="filled"
        value={(value ?? fields.html) ?? ''}
        onChange={(e) => onFieldChange('html', e.target.value) }
        disabled={disabled}
      />
    </div>
  );
}
