import { Template } from '../templates';
import '../styles';
import { SentEmailProps } from '../types';
import { Utils } from '../util';


export function SentEmail(props: SentEmailProps) {
  
  const { email, onClick, selected } = props;

  function getTime() {
    if (email.status === 'Sent') {
      let time = Utils.getRelativeDay(new Date(email.createdAt));
      if (time === 'Today') time = Utils.getTimeOfDay(new Date(email.createdAt));
      return time;
    }
  }
  
  let className = selected ? 'sentEmailSelected' : 'sentEmail';
  if (email.status === 'Draft') className = selected ? 'composedEmailSelected' : 'composedEmail';

  return (
    <div className={className} onClick={onClick}>
      <div className={'sentEmailHeader'}>
        <div className={'sentEmailSubject'}>{email.subject}</div>
        <div className={'sentEmailTime'} style={{color: email.draft ? 'red' : undefined}}>{email.status === "Draft" ? "Draft" : getTime()}</div>
      </div>
      <div className={'sentEmailBody'}>
        <div className={'sentEmailContent'}>{Template.HTML_TO_TEXT(email.contentHTML)}</div>
      </div>
    </div>
  );

}

