import { IconButton, TextField } from '@mui/material';
import { TextFields, TemplateFieldProps } from '../../types';
import { useAppContext } from '../../context';

export function TextForm(props: TemplateFieldProps) {
  const { disabled } = props;
  const { state, setState } = useAppContext();

  let fields = state.viewingDraft ? state.form.fields : state.sentForm.fields as TextFields;

  function onFieldChange(field: string, val: string) {
    // Change the form fields
    if (field === 'title') fields.title = val;
    if (field === 'text') fields.text = val;
    // Update the HTML
    let html = state.form.template!.getHTML(fields);
    // Update the state
    setState({...state, form: {...state.form, fields, contentHTML: html}});
  }

  return (
    <div>
      {/* Header */}
      <div className='inputFieldHeader'>
        <div className='inputFieldTitle'>Content</div>
      </div>
      {/* Title */}
      <TextField label="Title" fullWidth margin="normal" required
        value={fields.title ?? ''}
        onChange={(e) => onFieldChange('title', e.target.value) }
        disabled={disabled}
      />
      {/* Text */}
      <TextField label='Text' multiline fullWidth required minRows={8} maxRows={20} placeholder={"Enter Text"} variant="filled"
        value={fields.text ?? ''}
        onChange={(e) => onFieldChange('text', e.target.value) }
        disabled={disabled}
      />
    </div>
    
  );
}
