import { IconButton, TextField } from '@mui/material';
import { EventFields, TemplateFieldProps } from '../../types';
import { useAppContext } from '../../context';

export function EventForm(props: TemplateFieldProps) {
  const { disabled } = props;
  const { state, setState } = useAppContext();

  let fields = state.viewingDraft ? state.form.fields : state.sentForm.fields as EventFields;


  function onFieldChange(field: string, val: string) {
    // Change the form fields
    if (field === 'title') fields.title = val;
    if (field === 'image') fields.image = val;
    if (field === 'when') fields.when = val;
    if (field === 'where') fields.where = val;
    if (field === 'body') fields.body = val;
    if (field === 'link') fields.link = val;
    if (field === 'buttonText') fields.buttonText = val;
    // Update the HTML
    let html = state.form.template!.getHTML(fields);
    // Update the state
    setState({...state, form: {...state.form, fields, contentHTML: html}});
  }

  return (
    <div>
      {/* Header */}
      <div className='inputFieldHeader'>
        <div className='inputFieldTitle'>Content</div>
      </div>
      {/* Title */}
      <TextField label="Title" fullWidth margin="normal" required
        value={fields.title}
        onChange={(e) => onFieldChange('title', e.target.value) }
        disabled={disabled}
      />
      {/* Image */}
      <TextField label="Image URL" fullWidth margin="normal" required
        value={fields.image}
        onChange={(e) => onFieldChange('image', e.target.value) }
        disabled={disabled}
      />
      {/* When */}
      <TextField label="When" fullWidth margin="normal"
        value={fields.when}
        onChange={(e) => onFieldChange('when', e.target.value) }
        disabled={disabled}
      />
      {/* Where */}
      <TextField label="Where" fullWidth margin="normal" 
        value={fields.where}
        onChange={(e) => onFieldChange('where', e.target.value) }
        disabled={disabled}
      />
      {/* Body */}
      <TextField label="Body" multiline fullWidth minRows={5} maxRows={10} placeholder={"Enter Text"} variant="filled"
        value={fields.body}
        onChange={(e) => onFieldChange('body', e.target.value) }
        disabled={disabled}
      />
      {/* Button Text */}
      <TextField label="Button Text" fullWidth margin="normal" required
        value={fields.buttonText}
        onChange={(e) => onFieldChange('buttonText', e.target.value) }
        disabled={disabled}
      />
      {/* Link */}
      <TextField label="Link" fullWidth margin="normal" required
        value={fields.link}
        onChange={(e) => onFieldChange('link', e.target.value) }
        disabled={disabled}
      />
    </div>
    
  );
}
