import '../styles';
import { useEffect, useRef, useState } from 'react';
import { Panel } from 'react-resizable-panels';
import { Button } from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';
import { Email, FormData } from '../types';
import { API, Utils } from '../util';
import { SentEmail } from '.';
import { useAppContext } from '../context';
import { Template } from '../templates';


export function LeftPanel() {
  const { state, setState } = useAppContext();
  const [emails, setEmails] = useState<{count: number, list: Email[]}>({count: 0, list: []});
  const [loading, setLoading] = useState(true);
  const listRef = useRef<any>();

  // Get email list when component first renders
  useEffect(() => {
    getEmails();
  }, []);

  // Execute onComposePress if send similar is clicked
  useEffect(() => {
    if (state.sendingSimilar) onComposePress();
  }, [state.sendingSimilar]);

  // Refresh emails if we need to
  useEffect(() => {
    if (state.refreshEmailList) getRecentEmail();
  }, [state.refreshEmailList]);

  // Remove the top email if we are no longer composing
  useEffect(() => {
    if (!state.composing && emails.list.length > 0 && emails.list[0].draft) setEmails({count: emails?.count, list: emails.list.slice(1)});
  }, [state.composing]);


  async function getEmails(append: boolean = false) {
    if (append && emails.list.length >= emails.count) return; 
    try {
      let limit = 50;
      let skip = append ? emails.list.length : 0;
      let list = await API.GET_EMAILS(limit, skip);
      let count = (await API.GET_EMAIL_COUNT()).count;
      setEmails({count, list: append ? [...emails.list, ...list] : list });
      setLoading(false);
      setState({...state, refreshEmailList: false});
    } 
    catch (error) {
      console.log(error);
    }
  }

  async function getRecentEmail() {

    try {
      setState({...state, refreshEmailList: false});
      console.log('refreshing');
      let list = await API.GET_EMAILS(1, 0);
      let count = await API.GET_EMAIL_COUNT();
      // the new list has the retrieved email and ignores the top composing email 
      setEmails({count:count.count, list: [list[0] ,...emails.list.slice(1)] });
    } 
    catch (error) {
      console.log(error);
    }
  } 

  function onComposePress() {
    let composingEmail: Email = {
      subject: 'New Subject',
      contentHTML: '',
      contentText: '',
      emails: [],
      id: '-1',
      status: 'Draft',
      createdAt: 'now',
      draft: true
    };
    
    let template = state.sendingSimilar ? Template.GET_TEMPLATE_FROM_HTML(state.selectedEmail!.contentHTML) : null;
    let fields = template?.getFields(state.selectedEmail!.contentHTML);
    if (!fields) fields = {html: state.sendingSimilar ? state.selectedEmail!.contentHTML : ''}
    let tag = state.sendingSimilar ? Template.GET_TAG_FROM_HTML(state.selectedEmail!.contentHTML) : '';
    
    let form: FormData = {
      subject: state.sendingSimilar ? state.selectedEmail!.subject : '',
      contentHTML: state.sendingSimilar ? state.selectedEmail!.contentHTML : '',
      tag,
      template,
      fields,
    };
    // update the email list
    if(emails.list.length === 0 || !emails.list[0].draft) setEmails({count: emails?.count, list: [composingEmail, ...emails.list]});
    // update the state
    setState({ ...state, composing: true, viewingDraft: true, sendingSimilar: false, selectedEmail: composingEmail, form });
  }

  function onListScroll() {
    // Check if the user has scrolled to the bottom
    const container = listRef.current;
    if (container.scrollTop + container.clientHeight === container.scrollHeight) {
      // Get more emails if we are at the bottom of the list and have not loaded them all
      getEmails(true); 
    }
  }

  async function onSentEmailClick(e: Email) {

    if (e.draft) {
      setState({...state, viewingDraft: true, selectedEmail: e});
    } 
    else {
      let template = Template.GET_TEMPLATE_FROM_HTML(e.contentHTML);
      let fields = template?.getFields(e.contentHTML);
      let tag = Template.GET_TAG_FROM_HTML(e.contentHTML);
      if (e.openCount === undefined && tag !== "") e.openCount = await API.GET_EMAIL_READS(tag);
      setState({...state, viewingDraft: false, selectedEmail: e, sentForm: {subject: e.subject, contentHTML: e.contentHTML, fields, template, tag }});
    }
    
  }

  return (
    <Panel minSize={15} defaultSize={20} className='panelContentContainer'>
      {/* Render Header */}
      <div className='emailListHeader'>
        <div className='emailListTitle'>{`${emails?.count} Email${emails?.count === 1 ? '' : 's'} Sent`}</div>
        <Button variant="contained" endIcon={<EmailIcon/>} onClick={onComposePress} disabled={state.composing}>New</Button>
      </div>
      {/* Render List */}
      <div className='emailListContainer' ref={listRef} onScroll={onListScroll}>
        {!loading && emails.list.length > 0 && emails?.list.map(e => <SentEmail email={e} key={e.id} selected={e.id === state.selectedEmail?.id} onClick={() => onSentEmailClick(e)}/>)}
        {!loading && emails.list.length === 0 && <div className='noEmailsMessage'>No emails sent.</div>}
        {loading && Utils.LoadingContent()}
      </div>
    </Panel>
  );

}

