import { StudentAccount } from "../types";
import { Oval } from 'react-loader-spinner';
import '../styles';

export class Utils {
 
  private static accounts: StudentAccount[];

  /** Get time of day of this timestamp. Ex - 4:32 PM. */
  static getTimeOfDay(time: Date): string {
    // Extract the hour and minute from the given timestamp
    let hours = time.getHours();
    let minutes = time.getMinutes();
    let suffix = time.getHours() >= 12 ? 'PM' : 'AM'; 
    // Find current hour in AM-PM Format. 
    hours = hours % 12;  
    // To display "0" as "12" from the last line
    if(hours === 0) hours = 12;
    // Return the result
    return (hours) + ':' + (minutes < 10 ? ("0" + minutes) : minutes) + ' ' + suffix;
  }
  
  /** Get the day of this timestamp. Ex - Today, Yesterday, Monday, 11/21/2020, etc.  */
  static getRelativeDay(time: Date) {
    // Extract info from the current date
    let date = time.getDate();
    let dayOfWeek = (time.toString().split(' ')[0]);
    let month = time.getMonth() + 1;
    let year = time.getFullYear();
    // convert day of week to full string
    if(dayOfWeek ===  "Mon") dayOfWeek = "Monday";
    else if(dayOfWeek ===  "Tue") dayOfWeek = "Tuesday";
    else if(dayOfWeek ===  "Wed") dayOfWeek = "Wednesday";
    else if(dayOfWeek ===  "Thu") dayOfWeek = "Thursday";
    else if(dayOfWeek ===  "Fri") dayOfWeek = "Friday";
    else if(dayOfWeek ===  "Sat") dayOfWeek = "Saturday";
    else dayOfWeek = "Sunday";
    // Check to see if the current time is close to the given timestamp 
    let currentTime = new Date();
    let isClose = year === currentTime.getFullYear() && month === (currentTime.getMonth() + 1);
    // Return a special date if we are within the same week as the given timestamp
    if (isClose) {
      if(date === currentTime.getDate()) return "Today";
      else if(date === currentTime.getDate() - 1) return "Yesterday";
      else if( date >= (currentTime.getDate() - 7)) return dayOfWeek;
    }
    // if we are not close in time, return a basic timestamp format
    return (month + "/" + date + "/" + year);
  }

  /** Get a greeting depending on the time of day. Ex - Good Afternoon. */
  static getGreeting() {
    // Extract info from the current time
    let time = Utils.getTimeOfDay(new Date());
    let isAm = time.endsWith("AM");
    let colonPosition = time[2] === ":" ? 2 : 1;
    let hour = Number.parseInt(time.substring(0,colonPosition));
    // Give specific greetings for the morning
    if(isAm) {
      if(hour < 4) return "Good Evening";
      return "Good Morning";
    } 
    // And other greetings for the night.
    else {
      if(hour < 6) return "Good Afternoon";
      else return "Good Evening";
    }
  }

  /** Get the list of emails based on a language. */
  static getEmails(language: string) {
    if (language === 'None') return [];

    // Add both emails of each account to the list, if they exist
    let set = new Set<string>();
    for (let i = 0; i < this.accounts.length; i++) {
      const account = this.accounts[i];
      // Filter by language
      if (language === 'All' || account.preferedLanguage === '' || account.preferedLanguage === language) {
        if (account.email) set.add(account.email);
        if (account.email2) set.add(account.email2);
      }
    }
    // Remove any invalid emails
    let emails = Array.from(set).filter(e => this.validEmail(e));
    console.log(emails);
    return emails;
  }

  /** Set all the student accounts. */
  static setAccounts(accounts: StudentAccount[]) {
    this.accounts = accounts;
  }

  /** The JSX code for loading content. */
  static LoadingContent() {
    return(
      <div className='loadingContainer'>
        <Oval height={80} width={80} color={'#fefefe'} visible={true} secondaryColor={'#2E99FF'} strokeWidth={4} strokeWidthSecondary={4}/>
      </div>
    );
  }

   /** Check if a string is a valid email. */
  static validEmail(email: string): boolean {
    // Regular expression for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // Test the email against the regex
    return emailRegex.test(email);
  }

}
  
  