import '../styles';
import { Panel } from 'react-resizable-panels';
import { useAppContext } from '../context';

export function RightPanel() {

  const { state } = useAppContext();

  function getHTMLPreview() {
    if (!state.selectedEmail) return; 
    return state.viewingDraft ? state.form.contentHTML : state.sentForm.contentHTML;
  }

  return (
    <Panel minSize={20} defaultSize={40} className='panelContentContainer'>
      {state.selectedEmail && <iframe title={state.selectedEmail.id} className='preview' srcDoc={getHTMLPreview()}/>}
      {!state.selectedEmail && <div className='previewEmpty'>No preview to render.</div>}
    </Panel>
  );

}

